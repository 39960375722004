<template>
  <v-container fluid class="px-0">
    <DailyReportCard />
  </v-container>
</template>

<script>
import DailyReportCard from "@/components/layout/sales-and-costs/DailyReportCard";
export default {
  name: "DailyView",
  components: { DailyReportCard }
};
</script>

<style scoped></style>
