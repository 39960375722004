<template>
  <v-data-table
    class="daily-sales-costs-table"
    :items="items"
    :headers="headers"
    mobile-breakpoint="0"
    dense
    hide-default-footer
    disable-pagination
  >
    <template v-slot:item="{ item, headers, index }">
      <tr :class="getRowClass(item.day)">
        <td
          v-for="header in headers"
          :key="`item-${index}-${header.value}`"
          :class="`${getAlignClass(header.align)} ${header.cellClass}`"
        >
          <span
            v-if="header.value === 'date'"
            :class="getClassForDay(item.day)"
          >
            {{ item.date }} ({{ dayArray[item.day] }})
          </span>

          <template v-else-if="header.value === 'sales'">
            {{ item.sales | toCurrency }}
          </template>

          <template v-else-if="header.value === 'costs'">
            {{ item.costs | toCurrency }}
          </template>

          <template v-else>
            {{ item[header.value] }}
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "TableDailySalesAndCosts",
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "",
          width: 80,
          value: "date",
          align: "center",
          cellClass: "px-2 font-weight-regular text-heading-2",
          sortable: false,
          divider: false
        },
        {
          text: this.$t("sales"),
          width: 50,
          value: "sales",
          align: "right",
          class: "font-weight-regular main--text lighten-2 text-heading-2",
          cellClass: "px-2 font-weight-regular text-heading-3",
          sortable: false,
          divider: false
        },
        {
          text: this.$t("costs"),
          width: 50,
          value: "costs",
          align: "right",
          class: "font-weight-regular main--text lighten-2 text-heading-2",
          cellClass: "px-2 font-weight-regular text-heading-3",
          sortable: false,
          divider: false
        }
      ],
      dayArray: ["日", "月", "火", "水", "木", "金", "土"]
    };
  },
  methods: {
    getClassForDay(day) {
      if (day === 0 || day === 6) {
        return "daily-sales-costs-weekend-table-cell";
      }
    },
    getRowClass(day) {
      if (day === 0) return "daily-sales-costs-table-row-sunday";

      if (day === 6) return "daily-sales-costs-table-row-saturday";
    },
    getAlignClass(align) {
      return `text-${align}`;
    }
  }
};
</script>

<style>
.daily-sales-costs-weekend-table-cell {
  color: #7c5200 !important;
}

.daily-sales-costs-table-row-saturday {
  background-color: #f9ffe0 !important;
}

.daily-sales-costs-table-row-sunday {
  background-color: #f3f0d5 !important;
}

.v-data-table.daily-sales-costs-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(.v-data-table__mobile-row) {
  border: unset !important;
}

.v-data-table.daily-sales-costs-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  border: unset !important;
}
</style>
