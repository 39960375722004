<template>
  <CardBase
    :title="$t('sales_and_costs_daily_title')"
    :help-button="false"
    :custom-date="date"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-slide-group
            show-arrows="always"
            v-model="chosenMonth"
            mobile-breakpoint="0"
            center-active
          >
            <v-slide-item
              v-for="(item, index) in months"
              :key="index"
              v-slot="{ active, toggle }"
              :value="item.id"
            >
              <v-btn
                active-class="font-weight-bold text-heading-3"
                :input-value="active"
                small
                text
                @click="toggle"
                plain
                :ripple="false"
                :disabled="loading"
              >
                {{ item.name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-col>

        <v-col cols="12">
          <TableDailySalesAndCosts :items="salesAndCosts" />
        </v-col>

        <v-col cols="12">
          <div class="font-weight-regular main--text lighten-3 text-heading-2">
            {{ $t("sales_and_costs_message") }}
          </div>
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import TableDailySalesAndCosts from "@/components/ui/TableDailySalesAndCosts";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "DailyReportCard",
  components: { TableDailySalesAndCosts, CardBase },
  created() {
    this.mapMonths();
  },
  computed: {
    ...mapGetters(["getUserReport", "getSalesCostsDailyData"]),
    date() {
      return dayjs(this.getSalesCostsDailyData.date).format("YYYY-MM-DD");
    }
  },
  data() {
    return {
      loading: false,
      chosenMonth: 0,
      months: [],
      salesAndCosts: []
    };
  },
  watch: {
    chosenMonth: function(newVal) {
      let year = newVal.split("-")[0];
      let month = newVal.split("-")[1];
      this.getDataFromApi({ month: month, year: year });
    }
  },
  methods: {
    getDataFromApi(param) {
      this.loading = true;
      this.$store
        .dispatch("SALES_COSTS_DAILY_GET", { ...param })
        .then(() => {
          let salesAndCosts = [];
          for (const item of this.getSalesCostsDailyData.deals) {
            salesAndCosts.push({
              date: dayjs(item.date).format("YYYY-MM-DD"),
              day: dayjs(item.date).day(),
              sales: item.sale,
              costs: item.cost
            });
          }
          this.salesAndCosts = salesAndCosts;
        })
        .finally(() => (this.loading = false));
    },
    mapMonths() {
      this.chosenMonth = this.getUserReport.monthly_deals[
        this.getUserReport.monthly_deals.length - 1
      ].month.id;
      this.months = this.getUserReport.monthly_deals.map(item => {
        return {
          id: item.month.id,
          name: item.month.name
        };
      });
    }
  }
};
</script>

<style scoped></style>
